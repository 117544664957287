body,html{
  @apply bg-secondary;
}

.App-link {
  color: #61dafb;
}
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.scroller1 {

  /* Apply animation to this element */
  -webkit-animation: scrolling-left1 20s linear infinite;
  animation: scrolling-left1 20s linear infinite;
}

.scroller2 {

  /* Apply animation to this element */
  -webkit-animation: scrolling-left2 20s linear infinite;
  animation: scrolling-left2 20s linear infinite;
}

@keyframes scrolling-left1 {
    0% {transform: translateX(0%);
        -webkit-transform: translateX(0%);}
      100% {transform: translateX(-100%);
          -webkit-transform: translateX(-100%);}
}
@keyframes scrolling-left2 {
    0% {transform: translateX(100%);
        -webkit-transform: translateX(100%);}
      100% {transform: translateX(0%);
          -webkit-transform: translateX(00%);}
}


